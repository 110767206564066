import React, { ReactElement } from "react";
import { graphql, Link } from "gatsby";
import { transparentize, readableColor } from "polished";
import Img from "gatsby-image";
import styled from "styled-components";
import { config, animated, useSpring } from "react-spring";
import Layout from "../components/layout";
import { AnimatedBox } from "../elements";
import SEO from "../components/SEO";
import { ChildImageSharp } from "../types";

const ArticleItem = styled(Link)<{ color: string }>`
    text-decoration: none;
    color: ${props => readableColor(`${props.color}`, `${props.theme.colors.grey}`, `${props.theme.colors.shade}`)};
`;

const Content = styled(AnimatedBox)<{ bg: string }>`
    background-color: ${props => transparentize(0.6, props.bg)};

    .gatsby-image-wrapper:not(:last-child) {
        margin-bottom: ${props => props.theme.space[10]};

        @media (max-width: ${props => props.theme.breakpoints[3]}) {
            margin-bottom: ${props => props.theme.space[8]};
        }
    }
`;

const ArticleBox = styled(AnimatedBox)`
    max-width: 1400px;
    margin: 0 auto;
`;

const Intro = styled(animated.div)`
    max-width: 960px;
    letter-spacing: -0.003em;
    --baseline-multiplier: 0.179;
    --x-height-multiplier: 0.35;
    line-height: 1.58;
`;

const ReadMore = styled(Link)<{ color: string }>`
    display: block;
    text-align: right;
    color: ${props => readableColor(`${props.color}`, `${props.theme.colors.grey}`, `${props.theme.colors.shade}`)};
`;

const StyledImage = styled(Img)`
    transition: all 0.3s ease 0s !important;

    :hover {
        transform: scale(1.1);
    }
`;

type PageProps = {
    data: {
        articles: {
            nodes: {
                title: string;
                intro?: string;
                prompt: string;
                slug: string;
                color: string;
                cover: ChildImageSharp;
            }[];
        };
    };
};

const Articles: React.FunctionComponent<PageProps> = ({ data: { articles } }) => {
    const pageAnimation = useSpring({
        config: config.slow,
        from: { opacity: 0 },
        to: { opacity: 1 },
    });

    const titleAnimation = useSpring({
        config: config.slow,
        from: { opacity: 0, transform: "translate3d(0, -30px, 0)" },
        to: { opacity: 1, transform: "translate3d(0, 0, 0)" },
    });

    const descAnimation = useSpring({
        config: config.slow,
        delay: 600,
        from: { opacity: 0 },
        to: { opacity: 1 },
    });

    const imagesAnimation = useSpring({
        config: config.slow,
        delay: 800,
        from: { opacity: 0 },
        to: { opacity: 1 },
    });

    return (
        <Layout color="#000">
            <SEO title="Articles | Kellie" />
            {articles.nodes.map(
                (article: any): ReactElement => (
                    <ArticleItem
                        color={article.color}
                        key={article.slug}
                        to={`/${article.slug}`}
                        aria-label={`View article "${article.title}"`}
                    >
                        <Content style={pageAnimation} bg={article.color} py={8}>
                            <ArticleBox py={3} px={[6, 6, 8, 10]}>
                                <animated.h1
                                    style={titleAnimation}
                                    dangerouslySetInnerHTML={{ __html: article.title }}
                                />
                                <Intro style={descAnimation} color={article.color}>
                                    <div dangerouslySetInnerHTML={{ __html: article.intro }} />
                                </Intro>
                                <ReadMore
                                    color={article.color}
                                    key={article.slug}
                                    to={`/${article.slug}`}
                                    aria-label={`View article "${article.title}"`}
                                    dangerouslySetInnerHTML={{ __html: article.prompt }}
                                />
                            </ArticleBox>
                            <ArticleBox style={imagesAnimation} px={[6, 6, 8, 10]}>
                                <StyledImage
                                    fluid={article.cover.childImageSharp.fluid}
                                    style={{ margin: "1rem", maxHeight: "60vh" }}
                                    imgStyle={{ objectFit: "contain" }}
                                />
                            </ArticleBox>
                        </Content>
                    </ArticleItem>
                ),
            )}
        </Layout>
    );
};

export default Articles;

export const query = graphql`
    query Articles {
        articles: allArticlesYaml {
            nodes {
                title
                slug
                intro
                color
                prompt
                cover {
                    childImageSharp {
                        fluid(quality: 95, maxWidth: 1200) {
                            ...GatsbyImageSharpFluid_withWebp
                            presentationWidth
                        }
                    }
                }
            }
        }
    }
`;
